import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/philosophy",
    name: "philosophy",
    component: () => import("../views/PhilosophyView.vue"),
    meta: { textcolor: "#751584" },
  },
  {
    path: "/purpose",
    name: "purpose",
    component: () => import("../views/PurposeView.vue"),
    meta: { textcolor: "#9d0083" },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/ProfileView.vue"),
    meta: { textcolor: "#751584" },
  },
  {
    path: "/service",
    name: "service",
    component: () => import("../views/ServiceView.vue"),
    meta: { textcolor: "#5d1986" },
  },
  {
    path: "/project",
    name: "project",
    component: () => import("../views/ProjectView.vue"),
    meta: { textcolor: "#333" },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
    meta: { textcolor: "#333" },
  },
];
/* eslint-disable */
const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    let Position = Object;
    return new Promise((resolve, reject) => {
      if (savedPosition) {
        Position = savedPosition;
      } else {
        Position = { left: 0, top: 0 };
      }
      setTimeout(() => {
        resolve(Position);
      }, 500);
    });
  },
});

export default router;
