<template>
  <div class="home">
    <div class="layer2">
      <h2>
        <span class="t1">
          Where
          <br />
          there
          <br />
          is a will,
        </span>
        <span class="t2">
          there is
          <br />
          a way
        </span>
      </h2>
      <div>
        <h1><img src="@/assets/images/kuwadatemasu.svg" alt="企てます。" /></h1>
      </div>
      <p class="copy">© OTENTOMARU INC. All Rights Reserved.</p>
      <router-link class="about-btn" to="philosophy">Philosophy</router-link>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  name: "HomeView",
  setup() {
    const textanimation = () => {
      let maintext = ".layer2 h1";
      let subtext = ".layer2 h2";
      let tl = gsap.timeline({ repeat: 0 });
      tl.set(subtext, { opacity: 1 });
      tl.set(maintext, { opacity: 0 });
      tl.to(subtext, { opacity: 0, duration: 0.5 }, 2.5);
      tl.to(maintext, { opacity: 1, duration: 0.5 });
    };
    return { textanimation };
  },
  mounted() {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("load", setVh);
    window.addEventListener("resize", setVh);
    this.textanimation();
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}
.layer2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 $h-gap;
  overflow: hidden;
  text-align: center;
  z-index: 10;
  h2 {
    font-weight: 700;
    font-size: clamp(5rem, 9vw, 8.5rem);
    text-align: left;
    line-height: 0.8;
    margin-bottom: 2.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    span {
      display: block;
      &.t1 {
        color: #fef552;
      }
      &.t2 {
        color: #f9b900;
      }
    }
  }
  h1 {
    height: 65vmin;
    max-height: 80vmax;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    @include tb {
      height: 50vmax;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  h4 {
    display: inline-block;
    font-weight: 600;
  }
  .copy {
    font-size: 0.8rem;
    position: absolute;
    bottom: 3em;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
  .about-btn {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5rem;
    margin-top: 1em;
    display: none;
    background-color: $color_red;
    color: white;
    padding: 0.5em 2em;
    line-height: 1em;
    border-radius: 1em;
    @include tb {
      display: inline-block;
    }
  }
}
</style>
