<template>
  <div class="homearts">
    <div class="grp1">
      <div class="imgwrap hana3">
        <img src="@/assets/images/home/hana3.png" alt="花" />
      </div>
      <div class="imgwrap tentou">
        <img src="@/assets/images/home/tentou.png" alt="てんとうむし" />
      </div>
    </div>
    <div class="grp2">
      <div class="imgwrap hana2">
        <img src="@/assets/images/home/hana2.png" alt="花" />
      </div>
      <div class="imgwrap hana5">
        <img src="@/assets/images/home/hana5.png" alt="花" />
      </div>
    </div>
    <div class="imgwrap chou1">
      <img src="@/assets/images/home/chou1.png" alt="蝶" />
    </div>
    <div class="imgwrap hana4">
      <img src="@/assets/images/home/hana4.png" alt="花" />
    </div>

    <div class="imgwrap hana6">
      <img src="@/assets/images/home/hana6.png" alt="花" />
    </div>
    <div class="imgwrap kamome">
      <img src="@/assets/images/home/kamome.png" alt="かもめ" />
    </div>
    <div class="grp3">
      <div class="imgwrap hana1">
        <img src="@/assets/images/home/hana1.png" alt="花" />
      </div>
      <div class="imgwrap chou2">
        <img src="@/assets/images/home/chou2.png" alt="蝶" />
      </div>
    </div>
    <div class="grp4">
      <div class="imgwrap kitsune">
        <img src="@/assets/images/home/kitsune.png" alt="狐" />
      </div>
      <div class="imgwrap ki1">
        <img src="@/assets/images/home/ki1.png" alt="木" />
      </div>
    </div>
    <div class="grp5">
      <div class="imgwrap ki2">
        <img src="@/assets/images/home/ki2.png" alt="木" />
      </div>
      <div class="imgwrap niwatori">
        <img src="@/assets/images/home/niwatori.png" alt="にわとり" />
      </div>
      <div class="imgwrap kusa">
        <img src="@/assets/images/home/kusa.png" alt="草" />
      </div>
    </div>

    <div class="imgwrap ki3">
      <img src="@/assets/images/home/ki3.png" alt="木" />
    </div>
    <div class="imgwrap ki4">
      <img src="@/assets/images/home/ki4.png" alt="木" />
    </div>

    <div class="imgwrap kumo">
      <img src="@/assets/images/home/kumo.png" alt="雲" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.homearts {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;

  .imgwrap {
    position: absolute;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .grp1,
  .grp2,
  .grp3,
  .grp4,
  .grp5 {
    position: absolute;
    z-index: 1;
  }
  .grp1 {
    left: clamp(0px, 3vmax, 9999px);
    top: clamp(60px, 5vmax, 9999px);
    width: clamp(280px, 22vmax, 9999px);
    height: clamp(280px, 22vmax, 9999px);
    @include tb {
      width: 35vmin;
      height: 35vmin;
    }
    .hana3 {
      width: 100%;
      height: auto;
      z-index: 1;
      animation: hana3 10s infinite;
    }
    .tentou {
      z-index: 2;
      width: 25%;
      height: auto;
      left: 15%;
      top: 68%;
      animation: tentou 20s infinite;
      img {
        animation: tentoub 4s infinite;
      }
    }
  }
  .grp2 {
    width: clamp(280px, 25vmax, 9999px);
    height: clamp(280px, 25vmax, 9999px);
    left: 13%;
    bottom: 0;
    @include tb {
      width: clamp(150px, 25vmax, 9999px);
      height: clamp(150px, 25vmax, 9999px);
      left: -1%;
    }
    .hana2 {
      width: 42%;
      top: 12%;
      left: 45%;
      transform: rotate(10deg);
      img {
        transform-origin: bottom center;
        animation: grp2 6s infinite ease-in-out;
      }
    }
    .hana5 {
      width: 36%;
      top: 24%;
      left: 10%;
      transform: rotate(-10deg);

      img {
        transform-origin: bottom center;
        animation: grp2 6s 0.25s infinite ease-in-out;
      }
    }
  }
  .grp3 {
    left: 38%;
    bottom: 0;
    width: 25vw;
    height: 18vh;
    z-index: 4;

    .hana1 {
      left: 10%;
      top: 20%;
      width: 10vmax;
      min-width: 15vmin;
      animation: hana1 5s infinite;
    }

    .chou2 {
      right: 10%;
      width: 6vmax;
      min-width: 8vmin;
      animation: chou2 15s infinite;
      img {
        animation: chou2b 0.4s infinite;
      }
    }
  }
  .grp4 {
    right: 0;
    top: 8%;
    width: 24vmax;
    height: 40vmin;
    min-width: 40vmin;
    z-index: 2;
    .ki1 {
      left: 24%;
      top: 0;
      width: 50%;
    }
    .kitsune {
      width: 70%;
      top: 34%;
      left: 18%;
      img {
        animation: kitsune 8s infinite;
      }
    }
    @include tb {
      min-width: 50vw;
    }
  }
  .grp5 {
    right: -2%;
    bottom: 0;
    width: 32vmax;
    height: 55vmin;
    min-width: 60vmin;
    z-index: 3;

    .niwatori {
      width: 52%;
      right: 0;
      top: 15%;
      img {
        animation: niwatori 10s infinite;
        transform-origin: bottom;
      }
    }
    .ki2 {
      top: 40%;
      width: 45%;
      right: 40%;
    }
    .kusa {
      left: 18%;
      top: 16%;
      width: 20%;
      z-index: 2;
      transform: scaleX(-1);
    }
    @include tb {
      min-width: 40vw;
      height: 40vmax;
      right: -5%;
      .ki2 {
        width: 60%;
        top: auto;
        bottom: 0;
        right: 30%;
      }
      .kusa {
        top: -15%;
        left: 45%;
      }
    }
  }
  .chou1 {
    right: 26%;
    top: 15%;
    z-index: 2;
    width: 6vmax;
    min-width: 8vmin;
    z-index: 5;
    animation: chou1 10s infinite;
    img {
      animation: chou2b 0.4s infinite;
    }
    @include tb {
      top: 4%;
      right: 28%;
      width: 12vw;
    }
  }

  .hana4 {
    width: clamp(200px, 18vmax, 9999px);
    transform: scaleX(-1);
    left: -4%;
    bottom: -15%;
    z-index: 4;
    @include tb {
      width: clamp(80px, 15vmax, 9999px);
      bottom: 25%;
    }
  }
  .hana6 {
    transform: rotate(100deg);
    transform-origin: bottom center;
    bottom: 100%;
    left: 9%;
    width: clamp(8vmin, 8vmax, 9999px);
    z-index: 2;
    animation: hana6 6s infinite ease-out;
  }
  .kamome {
    left: 71%;
    z-index: 4;
    width: 8vmax;
    min-width: 15vmin;
    top: 40%;
    img {
      animation: kamome 6s infinite linear;
    }

    @include tb {
      left: 79%;
      width: 15vw;
      min-width: 0vw;
    }
  }

  .ki3 {
    width: clamp(280px, 28vmax, 9999px);
    right: -8vw;
    bottom: 40%;
    transform: scaleX(-1) translateY(50%);
    @include tb {
      bottom: 45%;
      width: 60vw;
      right: -35%;
    }
  }
  .ki4 {
    width: 20vmax;
    top: 10%;
    left: -4%;
    transform: rotate(-10deg) translateY(50%);
    @include tb {
      width: 40vw;
      left: -20%;
      top: 20%;
    }
  }
  .kumo {
    right: -8%;
    top: -10%;
    width: 42vmax;
    height: auto;
    min-width: 60vmin;
    z-index: 1;
  }
}
</style>
