<template>
  <nav class="mainnavigation">
    <div class="header-title">
      <router-link to="/">
        <img src="@/assets/images/logo.svg" alt="株式会社おてんと丸" />
        <span>OTENTO-MARU</span>
      </router-link>
    </div>
    <ul :class="{ active: navState }">
      <li>
        <router-link
          :class="{ active: PageName == 'philosophy' }"
          @click="navState = false"
          to="/philosophy"
          data-text="理念"
        >
          PHILOSOPHY
        </router-link>
      </li>
      <li>
        <router-link
          :class="{ active: PageName == 'purpose' }"
          @click="navState = false"
          to="/purpose"
          data-text="存在意義"
        >
          PURPOSE
        </router-link>
      </li>
      <li>
        <router-link
          :class="{ active: PageName == 'profile' }"
          @click="navState = false"
          to="/profile"
          data-text="プロフィール"
        >
          PROFILE
        </router-link>
      </li>
      <li>
        <router-link
          :class="{ active: PageName == 'service' }"
          @click="navState = false"
          to="/service"
          data-text="業務内容"
        >
          SERVICE
        </router-link>
      </li>
      <li>
        <router-link
          :class="{ active: PageName == 'project' }"
          @click="navState = false"
          to="/project"
          data-text="自社事業"
        >
          PROJECT
        </router-link>
      </li>
      <li>
        <router-link
          :class="{ active: PageName == 'contact' }"
          @click="navState = false"
          to="/contact"
          data-text="お問い合わせ"
        >
          CONTACT
        </router-link>
      </li>
    </ul>
    <div
      id="nav-btn"
      @click="navState = !navState"
      :class="{ active: navState }"
    >
      <span></span>
      <span></span>
    </div>
  </nav>
</template>

<script>
import {
  disableBodyScroll,
  // enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
export default {
  data() {
    return {
      navState: false,
      PageName: "home",
    };
  },
  watch: {
    $route(to) {
      if (to.name.match("home")) {
        this.PageName = "home";
      } else {
        this.PageName = to.name;
      }
    },
    navState: function (to) {
      if (to === true) {
        const nav = document.querySelector("*");
        disableBodyScroll(nav);
      } else {
        clearAllBodyScrollLocks();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  width: 100%;
  height: 100px;
  padding: 20px $h_gap;
  display: flex;
  z-index: 999;
  position: fixed;

  @include tb {
    height: 80px;
    padding: 15px $h_gap;
  }
  @include sp {
    height: 60px;
    padding: 10px $h_gap;
  }

  .header-title {
    align-items: center;
    flex-grow: 1;
    z-index: 2;
    flex-shrink: 0;
    min-width: 240px;
    a {
      height: 100%;
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
    }
    img {
      height: 100%;
      width: auto;
    }
    span {
      margin-left: 1rem;
      font-weight: 600;
      letter-spacing: 0.1em;
      white-space: pre;
    }
  }
  ul {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
    z-index: 1;
    transition: 0.5s;
    @include pc {
      font-size: 0.9em;
      gap: 1.5rem;
    }
    @include tb {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(100%);
      width: 100%;
      height: 100vh;
      background-color: rgba(255, 255, 255, 0.8);
      flex-direction: column;
      align-items: flex-end;
      padding: calc(60px + 2rem) $h_gap;
      gap: 3.5rem;
      &.active {
        transform: none;
      }
    }
    a {
      position: relative;
      &::after {
        content: attr(data-text);
        position: absolute;
        bottom: -1.25rem;
        left: 0;
        font-size: 0.8rem;
        white-space: pre;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s;
      }
      &::before {
        position: absolute;
        content: "";
        display: block;
        width: 0;
        height: 2em;
        background-color: rgba(240, 240, 240, 0.6);
        z-index: -1;
        left: -1em;
        top: -0.25em;
        border-radius: 1em;
        opacity: 0;
        transition: 0.2s;
        @include tb {
          display: none;
        }
      }
      &:hover {
        &::after {
          visibility: visible;
          opacity: 0.8;
        }
      }
      &.active::before {
        opacity: 1;
        width: calc(100% + 2em);
      }
      @include tb {
        font-size: 2rem;
        font-weight: 700;
        padding: 1.5rem 0 1rem 0;
        &::after {
          bottom: 0rem;
          left: auto;
          right: 1em;
          font-size: 0.8rem;
          white-space: pre;
          visibility: visible;
          opacity: 0.8;
        }
      }
    }
  }
  #nav-btn {
    height: 60px;
    width: 60px;
    position: relative;
    display: none;
    margin-left: auto;
    z-index: 999;
    @include tb {
      display: block;
      height: 50px;
      width: 50px;
    }
    @include sp {
      height: 40px;
      width: 40px;
    }
    span {
      display: block;
      background-color: $fontcolor;
      width: 100%;
      height: 2px;
      position: absolute;
      transition: 0.2s;
      &:nth-child(1) {
        top: 25px;
      }
      &:nth-child(2) {
        top: 35px;
      }
      @include tb {
        &:nth-child(1) {
          top: 20px;
        }
        &:nth-child(2) {
          top: 30px;
        }
      }
      @include sp {
        &:nth-child(1) {
          top: 15px;
        }
        &:nth-child(2) {
          top: 25px;
        }
      }
    }
    &.active {
      background-color: #fff;
      border-radius: 50%;
      span {
        top: 30px;
        &:nth-child(1) {
          transform: rotate(45deg) scaleX(0.8);
        }
        &:nth-child(2) {
          transform: rotate(-45deg) scaleX(0.8);
        }
        @include tb {
          top: 25px;
        }

        @include sp {
          top: 20px;
        }
      }
    }
  }
}
</style>
