<template>
  <Navigation />
  <div class="homearts_wrap">
    <transition name="homearts" mode="out-in" appear>
      <HomeArts
        v-if="this.$route.path == '/' || this.$route.path == '/home2'"
      />
    </transition>
  </div>
  <router-view v-slot="{ Component, route }">
    <transition :name="transitionName" mode="out-in">
      <div :key="route.path">
        <component :is="Component" :key="route.path"></component>
      </div>
    </transition>
  </router-view>
  <div id="background" :style="{ backgroundColor: ChangeBg() }"></div>
</template>
<script>
import Navigation from "@/components/MainNavigation.vue";
import HomeArts from "@/components/HomeArts.vue";

export default {
  setup() {},
  data() {
    return {
      transitionName: "route",
      textcolor: "#333",
    };
  },
  components: {
    Navigation,
    HomeArts,
  },
  watch: {
    $route(to, from) {
      if (to.name.match("home" || "home2")) {
        console.log("from pages to home");
        this.transitionName = "home";
      } else if (from.path == "/") {
        console.log("from home to pages");
        this.transitionName = "route";
      } else {
        this.transitionName = "route";
      }
    },
  },
  methods: {
    ChangeBg() {
      console.log(this.$route.path);
      let color = "#fff";
      if (this.$route.path == "/" || this.$route.path == "/home2") {
        color = "#56c1cb";
      } else if (
        this.$route.path.match(/philosophy$/) ||
        this.$route.path.match(/profile$/)
      ) {
        color = "#f29f9b";
      } else if (this.$route.path.match(/purpose$/)) {
        color = "#fef552";
      } else if (
        this.$route.path.match(/service$/) ||
        this.$route.path.match(/contact$/)
      ) {
        color = "#56c1cb";
      }
      return color;
    },
  },
};
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#background {
  position: fixed;
  top: -20vh;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 120vh;
  background-color: #56c1cb;
  transition: background-color 3s;
}

// transition
.route-enter-from {
  opacity: 0;
  filter: blur(20px);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.5s $easing;
}
.route-leave-to {
  opacity: 0;
  filter: blur(20px);
}
.home-enter-from {
  opacity: 0;
}
.home-enter-active,
.home-leave-active {
  transition: all 0.5s $easing;
}
.home-leave-to {
  opacity: 0;
}

//
.homearts_wrap {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.homearts-enter-from {
  opacity: 0;
  filter: blur(5px);
  &.homearts {
    transform: scale(1.5);
  }
}
.homearts-enter-active {
  transition: all 1.5s 1s $easing;
}
.homearts-leave-active {
  transition: all 1s $easing;
}
.homearts-leave-to {
  opacity: 0;
  filter: blur(20px);
  &.homearts {
    transform: scale(1.5);
  }
}
</style>
