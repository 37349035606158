import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "@/js/typekit.js";
import VueSmoothScroll from "vue3-smooth-scroll";
gsap.registerPlugin(ScrollTrigger);

const app = createApp(App);
app.use(router);
app.use(VueSmoothScroll);
app.mount("#app");
